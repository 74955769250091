import "./Home.css"
const Home = () => {
    return ( 
        <div className="home">
            <h3>你好！ 我是DiDi，我姓陳</h3>
            <h3>歡迎來到我唯一正式的網站</h3>
            <h1>我的故事</h1>
            <p>我二零零六年在台北台灣出生的。我沒有上學因為不喜歡看書。上班的話，我負責保護家人，吃東西，還有睡覺。整天做這些任務讓累得跟狗一樣。這一輩子我沒有交過女朋友，不夠人人都說我很可愛。哇哇。下班之後 我 做休閒活動，享受生活。我最愛在我們家樓上的陽台上曬太陽，看台北一零一大樓。年輕的時候 我也每天都去公園散步，跟其他狗玩耍。但老了以後，我沒有之前那麼多力氣了，所以覺得在家裡休息最舒服。我喜歡吃東西，可是因為保護身體健康，所以不會吃太多。除了天天都吃飼料以外，我覺得我主人給我的雞肉特別好吃。雖然我很勇敢，不怕人也不怕長得比我大的狗，也有我害怕，討厭的事。譬如，我不喜歡玩水 洗澡。有一點臭味沒關係。我討厭人抓我的耳朵 尾巴，碰鼻子，壓身體。請大家不要做這些 好不好</p>
            <h1>關鍵事實</h1>
            <div className='table'>
                <div>姓名</div>
                <div>陳DiDi</div>
                <div>年齡</div>
                <div>15歲</div>
                <div>顏色</div>
                <div>白</div>
                <div>國籍</div>
                <div>臺灣</div>
                <div>兄弟姐妹</div>
                <div>不曉得 :-(</div>
                <div>婚姻狀況</div>
                <div>未婚</div>
                <div>愛好</div>
                <div>睡覺，吃飯</div>
                <div>語言能力</div>
                <div>國語，台語</div>
            </div>
        </div>
     );
}
 
export default Home;