import "./Contact.css"


const Contact = () => {
    return ( 
        <div className="contact-page-container">
            <h1 className="contacts">聯繫方式</h1>
            <p>地址: 台北市信義區祥雲街陽台</p>
            <p>電話： 未</p>
            <p>電子郵件： 未</p>
            <p>賴： 未</p>
            <p>微信： 未</p>
            <p>臉書： 未</p>
            <p>推特： 未</p>
            <p>IG： 未</p>




        </div>
     );
}
 
export default Contact;